export enum Paths {
  Home = '/',
  Inbox = '/inbox',
  Upcoming = '/upcoming',
  // AllTasks = '/all-tasks',
  Goals = '/goals',
  GoalsCompleted = '/goals/completed',
  GoalsArchived = '/goals/archived',
  GoalsThisYear = '/goals/this-year',
  GoalsNextYear = '/goals/next-year',
  GoalsAll = '/goals/all',
  GoalDetail = '/goals/:id',
  GoalTemplates = '/goal-plans',
  LifeAreas = '/life-areas',
  LifeAreaDetailGoals = '/life-areas/:id/goals',
  LifeAreaDetail = '/life-areas/:id',
  LifeAreaDetailTasks = '/life-areas/:id/tasks',
  Habits = '/habits',
  HabitsCompleted = '/habits/completed',
  Journal = '/journal',
  Login = '/login',
  Signup = '/signup',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  VerifyEmail = '/verify-email',
}
