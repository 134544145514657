import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { FormInput } from 'shared/components/ui/form-input';
import { SignupErrorTypes, SignupFormFields } from 'shared/types/signup-form';

import * as Styled from './signup-form.style';
import { useSignupForm } from './use-signup-form';

export type LoginFormProps = {
  onSubmit: (loginData: SignupFormFields) => void;
  isLoading: boolean;
  error?: SignupErrorTypes;
};

export const SignupForm: React.FC<LoginFormProps> = ({
  onSubmit,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();
  const { register, onSubmit: submitForm, errors } = useSignupForm(onSubmit);

  const nameErrorMessage = useMemo(() => {
    if (isLoading) {
      return;
    }
    if (errors.name?.message) {
      return t('forms.signup.fields.name.error');
    }

    return undefined;
  }, [errors.name?.message, isLoading, t]);

  const emailErrorMessage = useMemo(() => {
    if (isLoading) {
      return;
    }
    if (errors.email?.message) {
      return t('forms.signup.fields.email.error');
    }

    if (error === SignupErrorTypes.EmailTaken) {
      return t('forms.signup.error.email-taken.label');
    }

    if (error === SignupErrorTypes.Unknown) {
      return t('forms.signup.error.unknown.label');
    }

    return undefined;
  }, [error, errors.email?.message, isLoading, t]);

  const passwordErrorMessage = useMemo(() => {
    if (isLoading) {
      return;
    }
    if (errors.password?.message) {
      return t('forms.signup.fields.password.error');
    }

    return undefined;
  }, [errors.password?.message, isLoading, t]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Fields>
        <Styled.FieldContainer>
          <FormInput
            {...register('name')}
            id="name"
            placeholder={t('forms.signup.fields.name.placeholder')}
            error={nameErrorMessage}
            disabled={isLoading}
          />
          {!!nameErrorMessage && (
            <Styled.ErrorMessage>{nameErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <FormInput
            {...register('email')}
            id="email"
            placeholder={t('forms.signup.fields.email.placeholder')}
            error={emailErrorMessage}
            disabled={isLoading}
          />
          {!!emailErrorMessage && (
            <Styled.ErrorMessage>{emailErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>

        <Styled.FieldContainer>
          <FormInput
            {...register('password')}
            id="password"
            type="password"
            placeholder={t('forms.signup.fields.password.placeholder')}
            error={passwordErrorMessage}
            disabled={isLoading}
          />
          {!!passwordErrorMessage && (
            <Styled.ErrorMessage>{passwordErrorMessage}</Styled.ErrorMessage>
          )}
        </Styled.FieldContainer>
      </Styled.Fields>

      <Button
        variant={Variants.Contained}
        color={Colors.Primary}
        size={Sizes.Large}
        type="submit"
        disabled={isLoading}
        fullWidth
        alignCenter
        disabledBackgroundOnly
      >
        {isLoading ? (
          <Styled.Loader color="primary" size={20} />
        ) : (
          t('forms.signup.buttons.sign-in.label')
        )}
      </Button>

      <Styled.TermsOfUse>
        {t('forms.login.agreement.1')}
        <Styled.ExternalAnchor href="https://griply.app/eula" target="_blank">
          {t('forms.login.agreement.terms-of-use')}
        </Styled.ExternalAnchor>
        {t('forms.login.agreement.2')}
        <Styled.ExternalAnchor
          href="https://griply.app/privacy-policy"
          target="_blank"
        >
          {t('forms.login.agreement.privacy-policy')}
        </Styled.ExternalAnchor>
        {t('forms.login.agreement.3')}
      </Styled.TermsOfUse>
    </Styled.Form>
  );
};
