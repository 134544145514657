import mixpanel from 'mixpanel-browser';
import { ID } from 'shared/types/id';

import { ActionEvents } from '../models/action-events';
import { EventProps } from '../models/event-props';
import { PageEvents } from '../models/page-events';
import { SuperProperties } from '../models/super-properties';

const pageEvents = Object.values(PageEvents);

export class MixpanelService {
  private static instance: typeof mixpanel;

  get mixpanel(): typeof mixpanel {
    if (!MixpanelService.instance) {
      MixpanelService.instance = mixpanel;
    }

    return MixpanelService.instance;
  }

  constructor(userId: ID, superProperties?: SuperProperties) {
    if (!MixpanelService.instance) {
      this.mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN, {
        debug: import.meta.env.MODE === 'development',
      });
    }

    this.mixpanel.identify(userId);
    superProperties && this.mixpanel.register(superProperties);
  }

  track<TrackEvent extends PageEvents | ActionEvents>(
    trackEvent: TrackEvent,
    eventInfo?: EventProps<TrackEvent>,
  ): void {
    if (pageEvents.includes(trackEvent as PageEvents)) {
      this.mixpanel.track('screen_view', {
        screen: trackEvent,
        ...(eventInfo ?? {}),
      });

      return;
    }

    this.mixpanel.track(trackEvent, eventInfo);
  }
}
