import { useCallback, useRef } from 'react';
import {
  ActionEvents,
  AppTypes,
  EventProps,
  MixpanelService,
  PageEvents,
} from 'shared/services/tracking';
import { ID } from 'shared/types/id';

import { useUser } from './use-user';

export const useTrackEvents = () => {
  const user = useUser();
  const mixpanelRef = useRef<MixpanelService>();
  const userIdRef = useRef<ID>();

  return useCallback(
    <TrackEvent extends ActionEvents | PageEvents>(
      event: TrackEvent,
      eventProps: EventProps<TrackEvent>,
    ) => {
      if (!user?.uid) {
        return;
      }

      if (!mixpanelRef.current || user.uid !== userIdRef.current) {
        mixpanelRef.current = new MixpanelService(user.uid, {
          app_type: window.electron
            ? window.electron.isMac
              ? AppTypes.ElectronMacApp
              : AppTypes.ElectronWinApp
            : AppTypes.WebApp,
        });
        userIdRef.current = user.uid;
      }

      mixpanelRef.current.track(event, eventProps);
    },
    [user?.uid],
  );
};
