import { confirmPasswordReset } from 'firebase/auth';
import { ResetPasswordErrorTypes } from 'shared/types/reset-password-form';

import { getAuthentication } from './helpers';
export const resetPassword = async (code: string, password: string) => {
  const auth = await getAuthentication();

  try {
    const result = await confirmPasswordReset(auth, code, password);
    return result;
  } catch (e) {
    throw ResetPasswordErrorTypes.Unknown;
  }
};
